import React from 'react';
import { Navigate } from 'react-router';
import USER_ROLES from 'src/constantes/userRoles';
import useAuth from 'src/hooks/useAuth';
import { BASE_PATH, PATH_DASHBOARD } from 'src/routes/path';

const useCurrentRole = () => {
  const { user } = useAuth();

  return user?.role;
};

/**
 * RoleBasedGuard component checks if the user has the required role to access the child components.
 * If the user does not have the required role, it redirects to the appropriate page based on the user's current role.
 *
 * @param {Array} accessibleRoles - The roles that are allowed to access the child components.
 * @param {ReactElement} children - The child components to be rendered if the user has the required role.
 * @returns {ReactElement} - The child components if the user has the required role, or redirects to a different page if not.
 */
const RoleBasedGuard = ({ accessibleRoles, children }) => {
  const currentRole = useCurrentRole();

  if (!accessibleRoles.includes(currentRole)) {
    if (currentRole === USER_ROLES.USER) {
      return <Navigate replace to={BASE_PATH.root} />;
    }
    return <Navigate replace to={PATH_DASHBOARD.administration.dashboard} />;
  }

  return <>{children}</>;
};

export default RoleBasedGuard;

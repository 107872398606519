const USER_ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

const roleHierarchy = {
  [USER_ROLES.USER]: [
    USER_ROLES.USER,
    USER_ROLES.ADMIN,
    USER_ROLES.SUPER_ADMIN,
  ],
  [USER_ROLES.ADMIN]: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
  [USER_ROLES.SUPER_ADMIN]: [USER_ROLES.SUPER_ADMIN],
};

/**
 * Check if a user's current role matches the required roles.
 *
 * @param {Object} options - The options object.
 * @param {string} options.currentRole - The user's current role.
 * @param {boolean} [options.excludeRoleHierarchy=false] - Whether to exclude the role hierarchy.
 * @param {string|string[]} options.rolesToCheck - The required roles to check against.
 * @returns {boolean} - True if the user's current role matches the required roles, false otherwise.
 */
const checkRole = ({
  currentRole,
  excludeRoleHierarchy = false,
  rolesToCheck,
}) => {
  const safeRequiredRoles = Array.isArray(rolesToCheck)
    ? rolesToCheck
    : [rolesToCheck];
  if (excludeRoleHierarchy) return safeRequiredRoles.includes(currentRole);
  return safeRequiredRoles.some((requiredRole) =>
    roleHierarchy[requiredRole].includes(currentRole)
  );
};

export { checkRole };
export default USER_ROLES;

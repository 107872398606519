const path = (root, subLink) => `${root}${subLink}`;

const ROOTS = '/';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/admin';

const PATH_AUTH = {
  login: path(ROOTS_AUTH, '/login'),
};

const BASE_PATH = {
  general: {
    mediaCenter: path(ROOTS, 'media-center'),
    virtualVisit: path(ROOTS, 'visite-virtuel'),
    clickablePlan: path(ROOTS, 'plan-cliquable'),
    underConstruction: path(ROOTS, 'page-en-construction'),
    underConstructionVisiteVirtuelle: path(
      ROOTS,
      'visite-virtuelle-en-construction'
    ),
    underConstructionPlanClickable: path(
      ROOTS,
      'plan-clickable-en-construction'
    ),
  },
  root: ROOTS,
};

const PATH_DASHBOARD = {
  administration: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    transfer: path(ROOTS_DASHBOARD, '/transfert'),
  },
  root: ROOTS_DASHBOARD,
  users: {
    list: path(ROOTS_DASHBOARD, '/user'),
  },
  basket: {
    corbeille: path(ROOTS_DASHBOARD, '/corbeille'),
  },
};

export { BASE_PATH, PATH_AUTH, PATH_DASHBOARD };

import { Navigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { BASE_PATH } from 'src/routes/path';

/**
 * A component that guards against authenticated guests.
 * If the user is authenticated, they will be redirected to the root path.
 * Otherwise, it renders the children.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to render.
 * @returns {ReactNode} - The rendered component.
 */
export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={BASE_PATH.root} />;
  }

  return <>{children}</>;
}

import React, { createContext, useCallback, useState } from 'react';
import { AlertDialog, ConfirmDialog } from 'src/components/dialogs';

const DIALOGS = {
  ALERT: AlertDialog,
  CONFIRM: ConfirmDialog,
};

let dialogConfirmResolve = () => null;
let dialogDiscardResolve = () => null;

const DialogsContext = createContext();

const DialogsProvider = ({ children }) => {
  const [dialogType, setDialogType] = useState();
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState();
  const [dialogMessage, setDialogMessage] = useState();

  const triggerDialog = ({ message, title, type }) => {
    setDialogType(type);
    setDialogTitle(title);
    setDialogMessage(message);
    setOpen(true);
  };

  const alert = useCallback(({ message, title }) => {
    triggerDialog({ message, title, type: 'ALERT' });
    return new Promise((resolve) => {
      dialogConfirmResolve = () => {
        setOpen(false);
        resolve(true);
      };
    });
  }, []);

  const confirm = useCallback(
    ({ message, title }) =>
      new Promise((resolve) => {
        triggerDialog({ message, title, type: 'CONFIRM' });
        dialogConfirmResolve = () => {
          setOpen(false);
          resolve(true);
        };
        dialogDiscardResolve = () => {
          setOpen(false);
          resolve(false);
        };
      }),
    []
  );

  const DialogComponent = DIALOGS[dialogType];

  return (
    <DialogsContext.Provider value={{ alert, confirm }}>
      {children}
      {DialogComponent && (
        <DialogComponent
          message={dialogMessage}
          onConfirm={dialogConfirmResolve}
          onDiscard={dialogDiscardResolve}
          open={open}
          title={dialogTitle}
        />
      )}
    </DialogsContext.Provider>
  );
};

export { DialogsContext, DialogsProvider };

import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Login from 'src/pages/auth/Login';
import USER_ROLES, { checkRole } from 'src/constantes/userRoles';
import { PATH_DASHBOARD } from 'src/routes/path';
import LoadingScreen from 'src/components/LoadingScreen';

/**
 * Higher-order component that guards access to routes based on user authentication and role.
 * If the user is not authenticated, it redirects to the login page.
 * If the user is authenticated but doesn't have the required role, it redirects to the dashboard.
 * If the user is authenticated and has the required role, it allows access to the route.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} - The guarded component.
 */
export default function AuthGuard({ children }) {
  const { isAuthenticated, user, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const isAdmin = pathname.split('/')?.includes('admin');
  const navigate = useNavigate();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    if (
      !isAdmin &&
      checkRole({
        currentRole: user?.role,
        rolesToCheck: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
      })
    ) {
      navigate(PATH_DASHBOARD.root);
    }
  }, [user, isAdmin, navigate]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}

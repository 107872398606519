import { Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import React from 'react';
import useAuth from 'src/hooks/useAuth';
import { userDomainName } from 'src/utils/formatColors';

const RootStyle = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  bottom: 0,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'fixed',
  right: 0,
  width: '100%',
  zIndex: 99999,
}));

const LoadingScreen = ({ shared, ...other }) => {
  const { user } = useAuth();
  const domainName = userDomainName(user);

  return (
    <RootStyle {...other}>
      <m.div
        animate={{ rotateY: 360 }}
        initial={{ rotateY: 0 }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatDelay: 1,
        }}
        style={{ display: 'flex', marginLeft: 5, marginTop: 10 }}
      >
        {!shared && (
          <Box
            component="img"
            src={`/logos/${domainName}.png`}
            alt={`${domainName}`}
            sx={{ height: 70, width: 100 }}
          />
        )}
      </m.div>

      <Box
        animate={{
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          opacity: [0.25, 1, 1, 1, 0.25],
          rotate: [270, 0, 0, 270, 270],
          scale: [1.2, 1, 1, 1.2, 1.2],
        }}
        component={m.div}
        sx={{
          border: (theme) =>
            `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
          borderRadius: '25%',
          height: 140,
          position: 'absolute',
          width: 140,
        }}
        transition={{ duration: 3.2, ease: 'linear', repeat: Infinity }}
      />

      <Box
        animate={{
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          rotate: [0, 270, 270, 0, 0],
          scale: [1, 1.2, 1.2, 1, 1],
        }}
        component={m.div}
        sx={{
          border: (theme) =>
            `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
          borderRadius: '25%',
          height: 160,
          position: 'absolute',
          width: 160,
        }}
        transition={{
          duration: 3.2,
          ease: 'linear',
          repeat: Infinity,
        }}
      />
    </RootStyle>
  );
};

export default LoadingScreen;

import React, { useEffect } from 'react';
import Router from './routes';

import 'simplebar/src/simplebar.css';

import './App.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { favicon } from './utils/formatColors';
import useAuth from './hooks/useAuth';

/**
 * App component
 *
 * This component represents the main entry point of the application.
 * It retrieves the authenticated user from the useAuth hook, generates
 * a favicon based on the user, and sets it as the application's favicon.
 * The component also renders the Router component, which handles routing
 * within the application.
 */
const App = () => {
  const { user, logout } = useAuth();
  const icon = favicon(user);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = icon;
  }, [icon]);

  useEffect(() => {
    if (
      performance.navigation.type === 0 ||
      performance.navigation.type === 2
    ) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Router />;
};

export default App;

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Custom hook to handle responsive behavior based on breakpoints.
 * @param {string} query - The type of query to perform ('up', 'down', 'between', 'only').
 * @param {string} key - The key of the breakpoint.
 * @param {string} start - The starting key of the breakpoint range.
 * @param {string} end - The ending key of the breakpoint range.
 * @returns {boolean|null} - The result of the media query evaluation or null if query is invalid.
 */
const useResponsive = (query, key, start, end) => {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key));

  const mediaDown = useMediaQuery(theme.breakpoints.down(key));

  const mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));

  const mediaOnly = useMediaQuery(theme.breakpoints.only(key));

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  if (query === 'between') {
    return mediaBetween;
  }

  if (query === 'only') {
    return mediaOnly;
  }
  return null;
};

export default useResponsive;

import axios from './axios';

/**
 * Checks if the given access token is valid.
 * @param {string} accessToken - The access token to check.
 * @returns {boolean} - True if the access token is valid, false otherwise.
 */
const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  return true;
};

/**
 * Set the session with the provided access token and user.
 * @param {Object} sessionData - The session data containing the access token and user.
 */
const setSession = ({ accessToken, user }) => {
  if (accessToken) {
    localStorage.setItem('accessToken', JSON.stringify({ accessToken, user }));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };

import styled from '@emotion/styled';
import { Box, Container, Stack } from '@mui/material';
import React from 'react';
import SignInForm from 'src/sections/auth/SignInForm';
import Page from 'src/components/Page';

const ContentStyle = styled('div')({
  margin: 'auto',
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const BoxStyle = styled(Box)({
  padding: 24,
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 5px 21px rgba(0, 0, 0, 0.1)',
  borderRadius: 8,
  width: 380,
});

const Login = () => {
  return (
    <Page title="Authentification">
      <Container>
        <ContentStyle>
          <Stack direction="row" alignItems="center">
            <BoxStyle>
              <SignInForm />
            </BoxStyle>
          </Stack>
        </ContentStyle>
      </Container>
    </Page>
  );
};

export default Login;

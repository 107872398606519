import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

const AlertDialog = ({ message, onConfirm, open, title }) => {
  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;

import React, { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import USER_ROLES from 'src/constantes/userRoles';
import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import useAuth from 'src/hooks/useAuth';

/**
 * Higher-order component that adds code-splitting and authentication loading behavior to a component.
 *
 * @param {React.Component} Component - The component to be wrapped
 * @returns {React.Component} - The wrapped component
 */
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isInitialized, user } = useAuth();

  const renderLoadingScreen = user && <LoadingScreen />;

  return (
    <Suspense fallback={renderLoadingScreen}>
      {isInitialized ? <Component {...props} /> : renderLoadingScreen}
    </Suspense>
  );
};

/**
 * Router component that defines the routes for the application.
 * @returns {JSX.Element} The JSX element representing the router.
 */
export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'mot-de-passe-oublie',
      children: [
        {
          path: '',
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'sso-authentification',
      children: [
        {
          path: '',
          element: (
            <GuestGuard>
              <SSOAuthentication />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      children: [
        {
          element: (
            <AuthGuard>
              <MediaCenter />
            </AuthGuard>
          ),
          path: '',
        },
        {
          element: (
            <AuthGuard>
              <MediaCenter />
            </AuthGuard>
          ),
          path: ':id',
        },
      ],
      path: 'media-center',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
    },
    {
      children: [
        {
          element: (
            <AuthGuard>
              <VirtualVisit />
            </AuthGuard>
          ),
          path: '',
        },
        {
          element: (
            <AuthGuard>
              <VirtualVisit />
            </AuthGuard>
          ),
          path: ':id',
        },
      ],
      path: 'visite-virtuel',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
    },
    {
      children: [
        {
          element: (
            <AuthGuard>
              <ClickablePlan />
            </AuthGuard>
          ),
          path: '',
        },
        {
          element: (
            <AuthGuard>
              <ClickablePlan />
            </AuthGuard>
          ),
          path: ':id',
        },
      ],
      path: 'plan-cliquable',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
    },
    {
      path: 'page-en-construction',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <AuthGuard>
              <UnderConstruction />
            </AuthGuard>
          ),
          path: '',
        },
      ],
    },
    {
      path: 'visite-virtuelle-en-construction',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <AuthGuard>
              <UnderConstruction />
            </AuthGuard>
          ),
          path: '',
        },
      ],
    },
    {
      path: 'plan-clickable-en-construction',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <AuthGuard>
              <UnderConstruction />
            </AuthGuard>
          ),
          path: '',
        },
      ],
    },
    {
      path: 'shared',
      children: [
        {
          path: ':id',
          element: <VirtualVisit />,
        },
      ],
    },
    {
      path: 'plan-cliquable-shared',
      children: [
        {
          path: ':id',
          element: <ClickablePlan />,
        },
      ],
    },
    {
      path: 'sso/login',
      children: [
        {
          path: '',
          element: <SSOLogin />,
        },
      ],
    },
    {
      path: 'sso/validating',
      children: [
        {
          path: '',
          element: <SSOValidation />,
        },
      ],
    },

    // Dashboard Routes
    {
      children: [
        {
          element: (
            <RoleBasedGuard
              accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
            >
              <DashboardPage />
            </RoleBasedGuard>
          ),
          path: '',
        },
        {
          element: (
            <RoleBasedGuard
              accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
            >
              <DashboardPage />
            </RoleBasedGuard>
          ),
          path: 'dashboard',
        },
        {
          element: (
            <RoleBasedGuard
              accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
            >
              <User />
            </RoleBasedGuard>
          ),
          path: 'user',
        },
        {
          element: (
            <RoleBasedGuard
              accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
            >
              <Corbeille />
            </RoleBasedGuard>
          ),
          path: 'corbeille',
        },
        {
          children: [
            {
              path: ':id',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
                >
                  <Transfer />
                </RoleBasedGuard>
              ),
            },
          ],
          element: (
            <RoleBasedGuard
              accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}
            >
              <Transfer />
            </RoleBasedGuard>
          ),
          path: 'transfert',
        },
        {
          element: <Navigate replace to="/" />,
          path: 'admin/*',
        },
      ],
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
    },

    // Main Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { element: <NotFound />, path: '404' },
        {
          element: (
            <RoleBasedGuard accessibleRoles={[USER_ROLES.USER]}>
              <HomePage />
            </RoleBasedGuard>
          ),
          index: true,
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS
const HomePage = Loadable(lazy(() => import('../pages/Home')));

const MainLayout = Loadable(lazy(() => import('../layouts/main/MainLayout')));

const Login = Loadable(lazy(() => import('../pages/auth/Login')));

const ForgotPassword = Loadable(
  lazy(() => import('../pages/forgot-password/ForgotPassword'))
);

const SSOAuthentication = Loadable(
  lazy(() => import('../pages/auth/SSOAuthentication'))
);

const MediaCenter = Loadable(
  lazy(() => import('../pages/media-center/MediaCenter'))
);

const VirtualVisit = Loadable(
  lazy(() => import('../pages/visite-virtuel/VirtualVisit'))
);

const ClickablePlan = Loadable(
  lazy(() => import('../pages/clickable-plan/ClickablePlan'))
);

const UnderConstruction = Loadable(
  lazy(() => import('../pages/under-construction/UnderConstruction'))
);

const SSOLogin = Loadable(lazy(() => import('../pages/auth/SSOLogin')));

const SSOValidation = Loadable(
  lazy(() => import('../pages/auth/SSOValidation'))
);

//Admin
const DashboardPage = Loadable(
  lazy(() => import('../pages/admin/DashboardPage'))
);
const DashboardLayout = Loadable(lazy(() => import('../layouts/admin/index')));
const User = Loadable(lazy(() => import('../pages/admin/User')));
const Corbeille = Loadable(lazy(() => import('../pages/admin/Corbeille')));
const Transfer = Loadable(lazy(() => import('../pages/admin/Transfer')));
const NotFound = Loadable(lazy(() => import('../pages/404/Page404')));

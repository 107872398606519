/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormProvider from 'src/components/hook-form/FormProvider';
import RHFInput from 'src/components/hook-form/RHFInput';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import LoadingScreen from 'src/components/LoadingScreen';

const TitleStyle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontSize: 20,
  fontWeight: theme.typography.fontWeightSemiMedium,
}));

const LabelStyle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightSemiMedium,
}));

const StackForgot = styled(Stack)(() => ({
  textAlign: 'right',
}));

const LoadingButtonStyle = styled(LoadingButton)(({ theme }) => ({
  textAlign: 'center',
  textTransform: 'none',
  backgroundColor: '#FA5D3A',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
  borderRadius: 4,
  color: '#000000',
  width: 150,
  minHeight: 40,
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightSemiMedium,
  marginTop: 5,
}));

const StackSubmit = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));

const LinkStyle = styled(Typography)(() => ({
  fontWeight: 'bold',
  cursor: 'pointer',
  color: '#FA5D3A',
  textAlign: 'center',
  marginTop: 15,
  '&:hover': {
    opacity: 0.7,
  },
}));

export default function SignInForm() {
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const navigate = useNavigate();

  const goToForgotPassword = () => {
    navigate('mot-de-passe-oublie');
  };

  const goToSSOPage = () => {
    navigate('sso-authentification');
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Adresse email invalide')
      .required('Adresse email requis'),
    password: Yup.string().required('Mot de passe requis'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  /**
   * Submits the form data and handles any errors that occur.
   *
   * @param {Object} data - The form data to be submitted.
   */
  const onSubmit = async (data) => {
    setSubmitError(null);
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);
      if (error.message) {
        setSubmitError(error.message);
      }
      reset();
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {/* {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>} */}

      <Stack spacing={2}>
        <TitleStyle>Connexion</TitleStyle>

        <Stack spacing={1}>
          <LabelStyle>Email</LabelStyle>
          <RHFInput name="email" placeholder="Email" />
        </Stack>

        <Stack spacing={1}>
          <LabelStyle>Mot de passe</LabelStyle>
          <RHFInput
            name="password"
            placeholder="********"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <StackForgot>
            {/* <LinkStyle onClick={() => goToForgotPassword()}>Mot de passe oublié ?</LinkStyle> */}
          </StackForgot>
        </Stack>

        {submitError && <Alert severity="error">{submitError}</Alert>}

        <StackSubmit>
          <LoadingButtonStyle type="submit" loading={isSubmitting}>
            Se connecter
          </LoadingButtonStyle>
        </StackSubmit>
      </Stack>
      <LinkStyle onClick={() => goToSSOPage()}>Se connecter via SSO</LinkStyle>
    </FormProvider>
  );
}

/**
 * Generates the URL for a user's image based on their domain name and size.
 *
 * @param {Object} user - The user object containing information about the user.
 * @param {string} [size='welcome'] - The size of the image to generate the URL for.
 * @returns {string} - The URL for the user's image.
 */
export function userImage(user, size = 'welcome') {
  const domainName = userDomainName(user);

  return `${process.env.PUBLIC_URL}/images/${domainName}/${size}.png`;
}

/**
 * Generates the URL for a user's image based on their domain name and size.
 *
 * @param {Object} user - The user object containing information about the user.
 * @returns {string} - The URL for the user's image.
 */
export function userDomainName(user) {
  const domainName = user?.email
    .substring(user?.email.lastIndexOf('@') + 1)
    .split('.')[0];

  if (domainName === 'paul-parker-properties') {
    return `proprietes-privees`;
  }

  return domainName;
}

/**
 * Returns the URL for the favicon of a user's domain.
 * @param {string} user - The user object.
 * @returns {string} - The URL for the favicon.
 */
export function favicon(user) {
  const domainName = userDomainName(user);

  return `${process.env.PUBLIC_URL}/favicon/${domainName}.png`;
}

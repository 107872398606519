import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

export default function RHFInput({ name, primary, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          {...other}
          InputProps={{
            sx: {
              borderRadius: '5px',
              '&.Mui-focused': {
                color: 'black',
              },
              '&.MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: primary ? primary : '#FA5D3A',
                },
              },
            },
          }}
          InputLabelProps={{
            shrink: false,
          }}
        />
      )}
    />
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import ThemeProvider from './theme';
import { DialogsProvider } from './context/DialogsContext';
import { AuthProvider } from './context/JWTContext';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import NotistackProvider from './components/SnackbarStyles';
import { CollapseDrawerProvider } from './context/CollapseDrawerContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <HelmetProvider>
      <CollapseDrawerProvider>
        <BrowserRouter>
          <ThemeProvider>
            <NotistackProvider>
              <MotionLazyContainer>
                <DialogsProvider>
                  <App />
                </DialogsProvider>
              </MotionLazyContainer>
            </NotistackProvider>
          </ThemeProvider>
        </BrowserRouter>
      </CollapseDrawerProvider>
    </HelmetProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

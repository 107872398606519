import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

const ConfirmDialog = ({
  message,
  onConfirm,
  onDiscard,
  open,
  title,
  children,
  mw,
  confirmBtnText = 'OK',
  discardBtnText = 'Annuler',
}) => {
  return (
    <Dialog fullWidth maxWidth={mw || 'xs'} open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {message && <DialogContent>{message}</DialogContent>}
      {children && children}
      <DialogActions>
        {onDiscard && (
          <Button onClick={onDiscard} sx={{ color: 'text.secondary' }}>
            {discardBtnText}
          </Button>
        )}
        {onConfirm && (
          <Button
            onClick={onConfirm}
            variant="contained"
            sx={{ backgroundColor: (theme) => `${theme.customColors.primary}` }}
          >
            {confirmBtnText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
